import axios from "~~/plugins/axios";
import {defineStore, storeToRefs} from "pinia";
import {useNotificationsStore} from "./notifications";
import {useUserStore} from "./user";

const app = useNuxtApp();
const $axios = axios(app).provide.axios;

class AuthStore {
  register = async (name, phone, password, confirmPassword, fullName) => {
    await $axios.post("/register", {
      name: name,
      phone: phone,
      password: password,
      password_confirmation: confirmPassword,
      full_name: fullName,
    });
  };
  registerWithOTP = async (
    phone,
    password,
    confirmPassword,
    otp,
    tagId
  ) => {
    const registerData = await $axios.post("/register-with-otp", {
      phone: phone,
      password: password,
      password_confirmation: confirmPassword,
      otp: otp,
      tagId: tagId
    });
    return registerData;
  };


  registerTagWithLogin = async (
      phone,
      password,
      confirmPassword,
      otp,
      tagId
  ) => {
    const registerData = await $axios.post("/register-tag-with-login", {
      phone: phone,
      password: password,
      password_confirmation: confirmPassword,
      otp: otp,
      tagId: tagId
    });
    return registerData;
  };

  generateOTP = async (phone, password) => {
    const otpRes = await $axios.post("/generate-otp", {
      phone: phone,
      password: password,
    });
    return otpRes;
  };
  generateOTPForNewUser = async (phone) => {
    const otpRes = await $axios.post("/generate-otp-for-new-user", {
      phone: phone,
    });
    return otpRes;
  };
  generateOTPForTagRegistration = async (phone) => {
    const otpRes = await $axios.post("/generate-otp-tag-registration", {
      phone: phone,
    });
    return otpRes;
  };


  checkIfOtpIsRight = async (phone, otp) => {
    // return;
    return await $axios.post('/check-otp', {
      phone, otp
    });
  };


  loginWithOTP = async (phone, otp) => {
    const loginData = await $axios.post("/login-with-otp", {
      phone,
      otp,
    });
    return loginData;
  };

  login = async (phone, password) => {
    await $axios.post("/login-user", {
      phone: phone,
      password: password,
    });
  };

  logout = async () => {
    await $axios.post("/logout");
    useUserStore().user.value = {};
  };

  sendVerificationLink = async () => {
    try {
      const response = await $axios.post("/email/verification-notification");
      return response.data;
    } catch (error) {
      return false;
    }
  };
  sendVerificationLinkWithCArdId = async (tagId) => {
    try {
      const response = await $axios.post(
        `/email/verification-notification-with-tagId/${tagId}`
      );
      return response.data;
    } catch (error) {
      return false;
    }
  };

  verifyEmail = async (id, hash) => {
    const { successNotification, errorNotification } = useNotificationsStore();

    try {
      await $axios(`/verify-email/${id}/${hash}`);
      successNotification("email verified successfully");
      return true;
    } catch (error) {
      errorNotification("failed to verify email");
      return false;
    }
  };

  changePassword = async (currentPassword, newPassword, cPassword) => {
    const { successNotification, errorNotification } = useNotificationsStore();
    try {
      await $axios.post("/api/change-password", {
        old_password: currentPassword,
        new_password: newPassword,
        new_password_confirmation: cPassword,
      });
      successNotification("password updated successfully");

      return true;
    } catch (error) {
      if (error.response.data.error == "wrong password") {
        errorNotification("wrong password");
        return;
      }
      errorNotification("password confirmation does not match password");
      return false;
    }
  };

  deleteAccount = async (password) => {
    const { successNotification, errorNotification } = useNotificationsStore();
    const { user } = storeToRefs(useUserStore());
    try {
      await $axios.post("/api/account", {
        password,
      });
      successNotification("account deleted successfully");
      user.value = {};
      return true;
    } catch (error) {
      errorNotification("wrong password");
      return false;
    }
  };
  isLoggedIn = async () => {
    try {
      await useUserStore().getUser();
      const { user } = storeToRefs(useUserStore());
      if (user.value.id) {
        return true;
      }
    } catch (err) {
      return false;
    }
  };
}

export const useAuthStore = defineStore("auth", () => new AuthStore());
